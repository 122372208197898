import { VideocamOutlined } from "@mui/icons-material";
import AddchartIcon from "@mui/icons-material/Addchart";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandIcon from "@mui/icons-material/Expand";
import RemoveDoneIcon from "@mui/icons-material/RemoveDone";
import { Box, Button, Checkbox, Stack, Tooltip } from "@mui/material";
import CollaspedOutlineIcon from "@rsuite/icons/CollaspedOutline";
import ExpandOutlineIcon from "@rsuite/icons/ExpandOutline";
import MenuIcon from "@rsuite/icons/Menu";
import UnvisibleIcon from "@rsuite/icons/Unvisible";
import VisibleIcon from "@rsuite/icons/Visible";
import { formatRelative } from "date-fns";
import { enUS } from "date-fns/esm/locale";
import { Timestamp } from "firebase/firestore";
import { motion } from "framer-motion";
import { useConfirm } from "material-ui-confirm";
import React, { useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IconButton, Table } from "rsuite"; // removed Dropdown
import { SingleFieldTextBox } from "../build/DeviceEditFields";
import { computeAvgStat } from "../components/analysis/AnalyzeDashboard";
import { useGlobal } from "../components/providers/GlobalProvider";
import TagManager from "../components/TagManager";
import { InfoTooltip } from "../dashboard/DashboardTable";
import Tags, { BasicThrowType } from "../dashboard/DashboardTags";
import { ThrowAnalysis } from "../firebase/converters/analysisSet";
import useLive from "../hooks/useLive";
import useNotify from "../hooks/useNotify";
import { CoreStats } from "../model/CoreStats";
import { AnalysisSet, AnalysisType, ThrowSummary } from "../model/throwSummary";
import { Handedness } from "../model/UserSettings";
import {
  deleteAnalysisSet,
  getHyzer,
  getNose,
  getTrueUserId,
  getUserId,
  POWER_USER_IDS,
  storeAnalysisSet,
  storeSummary,
} from "../summaryUtils";
import { mul } from "../utils/math";
import { formatNumber } from "../utils/measurement";
import {
  getThrowName,
  getVerboseThrowStyle,
  isBackhandSpin,
  isClockwiseSpin,
} from "../utils/throw";
import { passesAllFilters } from "./shared/utility";
import { TableRenderProps } from "./ThrowTable";

const { Column, HeaderCell, Cell } = Table;
const ROW_HEIGHT = 40;
type SortType = "desc" | "asc";

function renderDate(date: Date): string {
  const formatRelativeLocale = {
    lastWeek: "'last' eeee 'at' h:mm aa",
    yesterday: "'yesterday at' h:mm aa",
    today: "'today at' h:mm aa",
    tomorrow: "'tomorrow at' h:mm a",
    nextWeek: "eeee 'at' h:mm a",
    other: "M/d 'at' h:mm aa",
  };
  const locale = {
    ...enUS,
    // @ts-ignore
    formatRelative: (token) => formatRelativeLocale[token],
  };
  return formatRelative(date, new Date(), { locale });
}

export function renderSummaryDate(summary: ThrowSummary | ThrowAnalysis): string {
  if (summary.throwTime) {
    return renderDate(summary.throwTime.toDate());
  }
  if (summary.uploadTime) {
    return renderDate(summary.uploadTime.toDate());
  }
  return "";
}

export function basicThrowType(
  coreStats: CoreStats,
  handedness?: Handedness,
): keyof typeof BasicThrowType {
  const backhandSpin = isBackhandSpin(coreStats, handedness);
  if (!coreStats.upsideDown) {
    return backhandSpin ? "Backhand" : "Forehand";
  }
  if (getHyzer(coreStats) > 0) {
    // return backhandSpin ? "Thumber Grenade" : "Grenade";
    return "Grenade";
  } else {
    // return backhandSpin ? "Thumber" : "Tomahawk";
    return "Overhand";
  }
}

const ExpandCell = ({ rowData, expandedRowKeys, onChange, ...props }) => (
  <Cell {...props} style={{ padding: 5 }}>
    <IconButton
      appearance="subtle"
      onClick={() => {
        onChange(rowData);
      }}
      icon={
        expandedRowKeys.some((key) => key === rowData.id) ? (
          <CollaspedOutlineIcon />
        ) : (
          <ExpandOutlineIcon />
        )
      }
    />
  </Cell>
);

export function ThrowTableInternal(
  props: TableRenderProps & { userId: string; renderSets?: boolean },
) {
  const { userId } = props;
  const [sortColumn, setSortColumn] = useState<keyof ThrowSummary | "throwType">("throwTime");
  const [sortType, setSortType] = useState<SortType | undefined>(undefined);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [lastRowSelected, setLastRowSelected] = useState<string>("");
  const { isLiveRoute, liveEvent } = useLive();
  const navigate = useNavigate();
  const tableContainerRef = useRef(null);
  const notify = useNotify();
  const confirm = useConfirm();
  const { isShareRoute, isInspectRoute, prefersMetric, isLiveAccount } = useGlobal();

  const handedness = props.userSettings?.handedness ?? Handedness.RIGHT;

  const rows = props.docs;

  let data: ThrowSummary[] = useMemo(() => {
    if (rows?.length > props.limit) {
      rows.pop(); // curious the chain reaction this will cause (handling extra element here seems to be ok?)
    }
    if (!sortType) {
      return rows;
    }
    return rows.sort((a, b) => {
      let x, y;

      if (sortColumn === "throwType") {
        x = getThrowName(a, handedness);
        y = getThrowName(b, handedness);
      } else if (sortColumn === "rotPerSec") {
        x = Math.abs(a[sortColumn]);
        y = Math.abs(b[sortColumn]);
      } else {
        x = a[sortColumn];
        y = b[sortColumn];
      }

      if (sortType === "asc") {
        return x < y ? -1 : x > y ? 1 : 0;
      } else {
        return x < y ? 1 : x > y ? -1 : 0;
      }
    });
  }, [rows, props.limit, sortType, sortColumn, handedness]);

  if (props.tags.length > 0) {
    data = TagManager.filterByTags(props.tags, data);
  }

  if (props.filters.length > 0) {
    data = data.filter((row) => {
      return passesAllFilters(props.filters, row);
    });
  }

  // make a map from id to index
  const idToIndex = new Map();
  data.forEach((row, index) => {
    idToIndex.set(row.id, index);
  });

  const handleExpanded = (rowData) => {
    const index = idToIndex.get(rowData.id);

    let open = false;
    const nextExpandedRowKeys = [];

    expandedRowKeys.forEach((key) => {
      if (key === rowData.id) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(rowData.id);
    }

    setExpandedRowKeys(nextExpandedRowKeys);

    // Restore the saved scroll position after the state update.
    if (index !== undefined) {
      setTimeout(() => {
        let sum = ROW_HEIGHT * index;
        // add 2 * ROW_HEIGHT for each expanded row before the current one
        for (let i = 0; i < index; i++) {
          if (expandedRowKeys.includes(data[i].id)) {
            sum += 2 * ROW_HEIGHT + 20;
          }
        }
        tableContainerRef?.current.scrollTop(sum);
      }, 1);
    }
  };

  const renderIconButton = (props, ref) => {
    return <IconButton {...props} ref={ref} icon={<MenuIcon />} size={"sm"} />;
  };

  // No longer used with new hide feature, but keeping code incase functionality is needed again
  // const dropdown = (doc) => (
  //   <Dropdown renderToggle={renderIconButton} noCaret>
  //     <Dropdown.Item
  //       icon={doc.hidden === "true" ? <UnvisibleIcon /> : <VisibleIcon />}
  //       onClick={(e) => {
  //         storeSummary(getUserId(), doc.id, {
  //           hidden: doc.hidden === "true" ? "false" : "true",
  //         }).catch((e) => {
  //           console.warn(e);
  //         });
  //       }}
  //     >
  //       {doc.hidden === "true" ? "Unhide" : "Hide"}
  //     </Dropdown.Item>
  //   </Dropdown>
  // );

  const rowExpandedHeight = POWER_USER_IDS.get(getTrueUserId()) ? 280 : 150;

  const createSetFromSelectedRows = async () => {
    const setId = (Date.now() / 1000).toFixed(0);
    const values: ThrowSummary[] = selectedRows.map((id) => data[idToIndex.get(id)]);
    const avg: ThrowSummary = { ...computeAvgStat(values) };
    const commonTags = values.reduce((prev, cur) => {
      return prev.filter((v) => cur.tags?.includes(v));
    }, values[0].tags ?? []);
    if (commonTags) {
      avg.tags = commonTags;
    }
    const analysisSet: AnalysisSet = {
      id: setId,
      ids: selectedRows,
      createTime: Timestamp.now(),
      type: AnalysisType.MANUAL,
      avg,
      tagIntersect: commonTags,
    };
    await storeAnalysisSet(getUserId(), setId, analysisSet);
    return setId;
  };

  const showCheckAll = data.length !== selectedRows.length && selectedRows.length < 1;
  const showRemoveAll =
    selectedRows.length > 0 ||
    selectedRows.length >= 30 ||
    (selectedRows.length === data.length && selectedRows.length > 0);

  const hideThrows = async () => {
    const actionText = data[0]?.hidden === "true" ? "Unhide" : "Hide";
    const isPlural = selectedRows.length > 1;
    try {
      await confirm({
        title: `${actionText} throw${isPlural ? "s" : ""}`,
        description: [
          `Are you sure you want to ${actionText.toLowerCase()} the selected throw${isPlural ? "s" : ""}?`,
        ],
        confirmationText: actionText,
        cancellationText: "Cancel", // "Nevermind"
        cancellationButtonProps: { color: "inherit" },
        confirmationButtonProps: { color: "primary", variant: "contained" },
      });
      for (const id of selectedRows) {
        storeSummary(getUserId(), id, {
          hidden: data[0]?.hidden == "true" ? "false" : "true",
        }).catch((e) => {
          console.warn(e);
        });
      }
      notify(
        "info",
        `Throw${isPlural ? "s are" : " is"} now ${actionText == "Unhide" ? "un" : ""}hidden.`,
      );
      setSelectedRows([]);
    } catch (e) {
      // nothing for dismiss
    }
  };

  const checkboxOnChange = (e: React.ChangeEvent<HTMLInputElement>, rowData: ThrowSummary) => {
    setSelectedRows((existingRows) => {
      if (e.target.checked) {
        return [...existingRows, rowData.id];
      } else {
        return existingRows.filter((id) => id !== rowData.id);
      }
    });
  };

  const checkboxOnClick = (e: React.MouseEvent<HTMLInputElement>, rowData: ThrowSummary) => {
    if (!selectedRows.includes(rowData.id)) {
      // opposite is true
      setLastRowSelected(rowData.id);
    } else if (lastRowSelected == rowData.id) {
      setLastRowSelected(""); // reset if unchecked
    }

    if (e.shiftKey && selectedRows.length && lastRowSelected) {
      // is shift key, at least 1 row selected and we have an index to work with to connect
      let start = false;
      for (const el of data) {
        if (el.id == rowData.id) {
          // this is shift clicked item
          if (!start) {
            start = true;
          } else {
            // end condition
            break;
          }
        } else if (el.id == lastRowSelected) {
          if (!start) {
            start = true;
          } else {
            // end condition
            break;
          }
          // this is
        } else if (start) {
          // condition has been met to start adding rows
          if (!selectedRows.includes(el.id)) {
            selectedRows.push(el.id);
            setSelectedRows([...selectedRows]);
          }
        }
      }
      setLastRowSelected("");
    }
  };
  const deleteThrowSets = async () => {
    const isPlural = selectedRows.length > 1;
    const actionText = `Delete Throw Set${isPlural ? "s" : ""}`;
    const toastText = `Throw set${isPlural ? "s" : ""} deleted.`;
    function getDescription(): React.ReactNode {
      const titles = selectedRows.map((el) => data[idToIndex.get(el)]?.title);
      const listTitles = [];

      if (!titles || !titles.length) {
        // shouldn't occur with prior checks, but just as a safety net
        return <span>No throws were selected, please Cancel and revise your selection.</span>;
      }

      for (let i = 0; i < titles.length; i++) {
        const span = (
          <span key={`title-${i}`} style={{ fontWeight: 500 }}>
            {titles[i]}
          </span>
        );
        if (i === titles.length - 1 && i > 0) {
          // pre last title
          listTitles.push(<span key={`span-${i}`}> and </span>);
        } else if (i !== 0) {
          listTitles.push(<span key={`span-${i}`}>, </span>);
        }
        listTitles.push(span);
      }

      return (
        <span>
          This will permanently delete throw set{isPlural ? "s" : ""} {listTitles}.<br />
          <br />
          The throws in the set will not be deleted.
        </span>
      );
    }

    try {
      await confirm({
        title: actionText,
        description: getDescription(),
        confirmationText: actionText,
        cancellationText: "Cancel",
        cancellationButtonProps: { variant: "secondary" },
        confirmationButtonProps: { variant: "primary" },
      });
      const userId = getUserId();
      const allDeletes = selectedRows.map((id) => deleteAnalysisSet(userId, id));
      await Promise.all(allDeletes);
      notify("info", toastText);
      setSelectedRows([]);
    } catch (e) {
      // nothing for dismiss
    }
  };

  const createThrowHref = (throwId: string) => {
    if (props.renderSets) {
      if (isInspectRoute) {
        return throwId;
      }
      return `${isLiveRoute ? `/live/${liveEvent}` : ""}/throws/sets/${throwId}`;
    }
    if (isInspectRoute) {
      return throwId;
    }
    if (isShareRoute) {
      return `/s/throws/${userId}/${throwId}`;
    }
    return `${isLiveRoute ? `/live/${liveEvent}` : ""}/throws/${throwId}`;
  };

  return (
    <Stack gap={2}>
      <Stack justifyContent={"space-between"} direction="row">
        {!props.renderSets && (
          <Stack direction="row" gap={2} alignItems="center">
            <Button
              id={"analysis-make-set-button"}
              startIcon={<AddchartIcon style={{ marginRight: "5px" }} />}
              variant="primary"
              size="small"
              disabled={selectedRows.length <= 1 || selectedRows.length > 30}
              onClick={async () => {
                if (isLiveRoute && !isLiveAccount) {
                  // do redirect here
                  const setIds = selectedRows.join("/");
                  navigate(`/live/${liveEvent}/throws/sets/${setIds}`);
                  return;
                }

                const setId = await createSetFromSelectedRows();

                const action = (
                  <>
                    <Button
                      onClick={() => {
                        navigate(`/throws/sets/${setId}`);
                      }}
                    >
                      Go to set
                    </Button>
                  </>
                );

                notify("success", "Analysis set created.", {
                  action,
                });
                setSelectedRows([]); // clear when done with operation
              }}
            >
              Create Analysis Set
            </Button>
            {selectedRows.length > 0 ? (
              <Box
                component={motion.span}
                sx={{ color: selectedRows.length > 30 ? "warning.main" : "grey.600" }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
              >
                {selectedRows.length}/30 row(s) selected.
              </Box>
            ) : null}
          </Stack>
        )}
        {!props.renderSets && (
          <Button
            startIcon={
              data && data[0]?.hidden == "true" ? (
                <VisibleIcon style={{ marginRight: "5px" }} />
              ) : (
                <UnvisibleIcon style={{ marginRight: "5px" }} />
              )
            }
            variant="secondary"
            size="small"
            disabled={selectedRows.length <= 0}
            onClick={hideThrows}
          >
            {data && data[0]?.hidden == "true" ? "Unhide" : "Hide"}
          </Button>
        )}
        {props.renderSets && (
          <Button
            startIcon={<DeleteIcon style={{ marginRight: "5px" }} />}
            variant="primary"
            size="small"
            disabled={selectedRows.length < 1}
            onClick={deleteThrowSets}
          >
            {"Delete Throw Set(s)"}
          </Button>
        )}
      </Stack>

      <Table
        id={"analysis-throw-table"}
        ref={tableContainerRef}
        style={{ fontSize: "0.9rem" }}
        virtualized
        height={800}
        data={data}
        rowKey={"id"}
        headerHeight={ROW_HEIGHT}
        rowHeight={ROW_HEIGHT}
        loading={props?.isLoading}
        expandedRowKeys={expandedRowKeys}
        rowExpandedHeight={rowExpandedHeight}
        renderRowExpanded={(rowData: ThrowSummary) => {
          if (!rowData) {
            return null;
          }

          return (
            <Stack style={{ marginLeft: "3rem" }}>
              <Tags userId={userId} value={rowData} />
              <Box style={{ marginBottom: "1rem" }} />
              {!props.renderSets && <Box style={{ marginBottom: "1rem" }} />}
            </Stack>
          );
        }}
        // autoHeight={true}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={(sortColumn, sortType) => {
          setSortColumn(sortColumn as keyof ThrowSummary);
          setSortType(sortType);
        }}
      >
        {props.renderSets && (
          <Column width={70} align="center">
            <HeaderCell align="right">
              {(showCheckAll || showRemoveAll) && (
                <Tooltip placement="right" title={(showCheckAll ? "Check" : "Uncheck") + " All"}>
                  <Button
                    onClick={() => {
                      if (showCheckAll) {
                        setSelectedRows(data.map((row) => row.id));
                      } else {
                        setSelectedRows([]);
                      }
                    }}
                    size="small"
                    startIcon={showCheckAll ? <CheckIcon /> : <RemoveDoneIcon />}
                    variant="text"
                    color="primary"
                  />
                </Tooltip>
              )}
              {!showCheckAll && !showRemoveAll && <CheckIcon />}
            </HeaderCell>
            <Cell align="right" dataKey={"id"}>
              {(rowData: ThrowSummary) => (
                <Checkbox
                  size="medium"
                  style={{ marginTop: -15 }}
                  checked={selectedRows.includes(rowData.id)}
                  onChange={(e: React.ChangeEvent<Element>) => checkboxOnChange(e, rowData)}
                  onClick={(e: React.MouseEvent<Element, MouseEvent>) =>
                    checkboxOnClick(e, rowData)
                  }
                />
              )}
            </Cell>
          </Column>
        )}
        {!props.renderSets && (
          <Column width={70} align="center">
            <HeaderCell>
              {(showCheckAll || showRemoveAll) && (
                <Tooltip placement="right" title={(showCheckAll ? "Check" : "Uncheck") + " All"}>
                  <Button
                    onClick={() => {
                      if (showCheckAll) {
                        setSelectedRows(data.map((row) => row.id));
                      } else {
                        setSelectedRows([]);
                      }
                    }}
                    size="small"
                    startIcon={showCheckAll ? <CheckIcon /> : <RemoveDoneIcon />}
                    variant="text"
                    color="primary"
                  />
                </Tooltip>
              )}
              {!showCheckAll && !showRemoveAll && <CheckIcon />}
            </HeaderCell>
            <Cell dataKey={"id"}>
              {(rowData: ThrowSummary) => (
                <Checkbox
                  size="medium"
                  style={{ marginTop: -15 }}
                  checked={selectedRows.includes(rowData.id)}
                  onChange={(e) => checkboxOnChange(e, rowData)}
                  onClick={(e) => checkboxOnClick(e, rowData)}
                />
              )}
            </Cell>
          </Column>
        )}
        <Column sortable flexGrow={props.renderSets ? 3 : 2}>
          <HeaderCell>Throw Type</HeaderCell>
          <Cell dataKey={props.renderSets ? "title" : "throwType"}>
            {(rowData: ThrowSummary) => {
              const link = (
                <Link to={createThrowHref(rowData.id)} rel="noreferrer">
                  {props.renderSets
                    ? rowData.title
                    : `${getVerboseThrowStyle(isClockwiseSpin(rowData), handedness)} ${getThrowName(rowData, handedness)}`}
                </Link>
              );
              if (props.renderSets) {
                return (
                  <SingleFieldTextBox
                    allowEmpty={false}
                    input={rowData}
                    fieldName={"title"}
                    storeData={(v) => storeAnalysisSet(getUserId(), rowData.id, v)}
                    renderBody={() => link}
                    label=""
                  />
                );
              }

              return (
                <Stack direction="row" gap={2} sx={{ width: "100%" }}>
                  {rowData.videoMetadata && (
                    <VideocamOutlined sx={{ color: "#4f46e5", fontSize: 20 }} />
                  )}
                  {link}
                  </Stack>
              );
            }}
          </Cell>
        </Column>
        <Column sortable flexGrow={2}>
          <HeaderCell>Date</HeaderCell>
          <Cell dataKey="throwTime">
            {(rowData: ThrowSummary) => rowData.throwTime && renderDate(rowData.throwTime.toDate())}
          </Cell>
        </Column>
        <Column sortable flexGrow={1}>
          <HeaderCell align="right">Speed</HeaderCell>
          <Cell align="right" dataKey={prefersMetric ? "speedKmh" : "speedMph"}>
            {(rowData: ThrowSummary) => {
              return formatNumber(
                rowData.speedKmh
                  ? prefersMetric
                    ? rowData.speedKmh
                    : rowData.speedMph
                  : rowData.speedMph,
                {
                  maximumFractionDigits: 0,
                },
              );
            }}
          </Cell>
        </Column>
        <Column sortable flexGrow={1}>
          <HeaderCell align="right">Hyzer</HeaderCell>
          <Cell align="right" dataKey="hyzerAngle">
            {(rowData: ThrowSummary) =>
              formatNumber(getHyzer(rowData), { maximumFractionDigits: 1 })
            }
          </Cell>
        </Column>
        <Column sortable flexGrow={1}>
          <HeaderCell align="right">Spin</HeaderCell>
          <Cell align="right" dataKey="spinRpm">
            {(rowData: ThrowSummary) =>
              formatNumber(
                rowData.spinRpm ?? Math.abs(mul(rowData?.rotPerSec ?? 0, 60).toNumber()),
                { maximumFractionDigits: 0 },
              )
            }
          </Cell>
        </Column>
        <Column sortable flexGrow={1}>
          <HeaderCell align="right">Nose</HeaderCell>
          <Cell align="right" dataKey="noseAngle">
            {(rowData: ThrowSummary) =>
              formatNumber(getNose(rowData), { maximumFractionDigits: 1 })
            }
          </Cell>
        </Column>
        <Column sortable flexGrow={1}>
          <HeaderCell align="right">Launch</HeaderCell>
          <Cell align="right" dataKey="launchAngle">
            {(rowData: ThrowSummary) =>
              formatNumber(rowData.launchAngle ?? rowData.uphillAngle, { maximumFractionDigits: 1 })
            }
          </Cell>
        </Column>
        <Column flexGrow={1} sortable>
          <HeaderCell>Wobble</HeaderCell>
          <Cell align="right" dataKey="wobble">
            {(rowData: ThrowSummary) =>
              formatNumber(rowData.wobble ?? rowData.offAxisDegrees, { maximumFractionDigits: 1 })
            }
          </Cell>
        </Column>
        {!props.renderSets && (
          <Column flexGrow={1} sortable>
            <HeaderCell>Distance</HeaderCell>
            <Cell align="right" dataKey={prefersMetric ? "distanceMeters" : "distanceFeet"}>
              {(rowData: ThrowSummary) => {
                return (
                  <>
                    {formatNumber(
                      (rowData.distanceFeet
                        ? prefersMetric
                          ? rowData.distanceMeters
                          : rowData.distanceFeet
                        : rowData.estimatedFeet) as number,
                      {
                        maximumFractionDigits: 0,
                      },
                    )}
                    {rowData.estimatedFlightNumbers && (
                      <InfoTooltip
                        text={"Simulated Flight"}
                        numbers={rowData.estimatedFlightNumbers}
                      />
                    )}
                  </>
                );
              }}
            </Cell>
          </Column>
        )}
        {!props.renderSets && (
          <Column width={70} align="center">
            <HeaderCell>
              <ExpandIcon />
            </HeaderCell>
            <ExpandCell expandedRowKeys={expandedRowKeys} onChange={handleExpanded} />
          </Column>
        )}
      </Table>
    </Stack>
  );
}
